import React from "react"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import ContactGlobal from "components/ContactGlobal"

import { Hero, Content } from "page_components/apartment"

const Apartment = ({ pageContext }) => {
  const dataInvestment = pageContext?.data?.data?.acfApartment?.investment[0]
  const region = dataInvestment?.acfInvestment?.region
  const fullSchema = JSON.parse(pageContext?.data?.data?.seo?.schema?.raw)

  const getRegion = region => {
    switch (region) {
      case "mountains":
        return {
          label: "w górach",
          title: "Inwestycje w górach",
          path: "/portfel-nieruchomosci/inwestycje-w-gorach/",
        }
      case "sea":
        return {
          label: "nad morzem",
          title: "Inwestycje nad morzem",
          path: "/portfel-nieruchomosci/inwestycje-nad-morzem/",
        }
      case "lakes":
        return {
          label: "warmia i mazury",
          title: "Warmia i Mazury",
          path: "/portfel-nieruchomosci/warmia-i-mazury/",
        }
      default:
        return
    }
  }

  const currentRegion = getRegion(region)

  return (
    <Layout
      seo={{
        title: pageContext?.data?.data?.seo.title
          ? pageContext?.data?.data?.seo.title
          : pageContext?.data?.title,
        description: pageContext?.data?.data?.seo.metaDesc
          ? pageContext?.data?.data?.seo.metaDesc
          : "Inwestycje w apartamenty premium. Zarabiaj inwestując w apartamenty inwestycyjne, hotelowe, komercyjne.",
      }}
    >
      <Breadcrumbs
        title={pageContext?.data?.title}
        subSubParent={{
          title: "Portfel nieruchomości",
          path: "/portfel-nieruchomosci/",
        }}
        subParent={{
          title: currentRegion?.title,
          path: currentRegion?.path,
        }}
        parent={{
          title: dataInvestment?.title,
          path: dataInvestment?.uri,
        }}
      />
      <Hero
        id={pageContext?.data?.id}
        title={
          pageContext?.data?.data?.acfApartment?.localNumber
            ? pageContext?.data?.data?.acfApartment?.localNumber
            : pageContext?.data?.title
        }
        data={pageContext?.data?.data?.acfApartment}
        dataInvestmentTitle={dataInvestment?.title}
        dataInvestment={dataInvestment}
        region={currentRegion}
      />
      <Content data={pageContext?.data?.data?.acfApartment} />
      <ContactGlobal />

      {fullSchema && (
        <script type="application/ld+json">
          {JSON.stringify({ ...fullSchema }, null, 2)}
        </script>
      )}
    </Layout>
  )
}

export default Apartment
